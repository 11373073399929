import React from 'react';
import { Link } from 'react-router-dom';
class NotFoundPage extends React.Component{
    render(){
        return <div>
         
            <p style={{textAlign:"center",height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
              <Link to="/">Go to Home </Link>
            </p>
          </div>;
    }
}
export default NotFoundPage;