// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    width: 87%;
    margin: 0 auto;
}
@media (max-width:480px) {
    .container {
       width: 100%;
    }
}
.div-preloader-fetch{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;right: 0;
    background-color: black;
}
.App{
    position: relative;
}
.PageMask{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 991px;
    margin: 0 auto;
    text-align: center;
    row-gap: 24px;
    padding: 20px;
    height: 100vh;
    height: 100dvh;
    line-height: 1.5;
}
.PageMask h1{
    font-size: 46px;
    text-transform: uppercase;
}
.PageMask p{
    font-size: 20px;
    font-weight: 300;
    font-family: "Inter";
}
.PageMask a{
    color: #000;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;AAClB;AACA;IACI;OACG,WAAW;IACd;AACJ;AACA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,SAAS,CAAC,QAAQ;IAClB,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,aAAa;IACb,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,yBAAyB;AAC7B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;AACxB;AACA;IACI,WAAW;IACX,0BAA0B;AAC9B","sourcesContent":[".container {\n    width: 87%;\n    margin: 0 auto;\n}\n@media (max-width:480px) {\n    .container {\n       width: 100%;\n    }\n}\n.div-preloader-fetch{\n    position: fixed;\n    top: 0;\n    left: 0;\n    bottom: 0;right: 0;\n    background-color: black;\n}\n.App{\n    position: relative;\n}\n.PageMask{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    max-width: 991px;\n    margin: 0 auto;\n    text-align: center;\n    row-gap: 24px;\n    padding: 20px;\n    height: 100vh;\n    height: 100dvh;\n    line-height: 1.5;\n}\n.PageMask h1{\n    font-size: 46px;\n    text-transform: uppercase;\n}\n.PageMask p{\n    font-size: 20px;\n    font-weight: 300;\n    font-family: \"Inter\";\n}\n.PageMask a{\n    color: #000;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
