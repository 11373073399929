// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgb(5, 5, 5);
  z-index: 99999999999;
  pointer-events: all;
}

.preloader-main {
  background: rgb(5, 5, 5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader-main::after {
  width: 120px;
  height: 120px;
  content: "";
  transition: all 0.5s;
  border-radius: 50%;
  border: 4px solid var(--ITSports-color-green);
  border-top-color: #000000;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(-360deg);
  }
}
.preloader-bg.main-loaded_hiding {
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/Loader.scss"],"names":[],"mappings":"AAIA;EACG,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,wBAAA;EACA,oBAAA;EACA,mBAAA;AAHH;;AAMC;EACC,wBAAA;EAEE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;AALJ;;AAUE;EACE,YAAA;EACA,aAAA;EACA,WAAA;EACA,oBAAA;EACA,kBAAA;EACA,6CAAA;EACA,yBAAA;EAEA,qCAAA;AARJ;;AAYE;EACE;IACE,uBAAA;EATJ;EAWE;IACE,0BAAA;EATJ;AACF;AAcG;EACA,UAAA;EACA,wBAAA;EACA,oBAAA;AAZH","sourcesContent":["\n\n\n\n.preloader-bg{\n   position: absolute;\n   top: 0;\n   left: 0;\n   bottom: 0;\n   right: 0;\n   width:100%;\n   height:100%;\n   background: rgb(5, 5, 5);\n   z-index: 99999999999;\n   pointer-events: all;\n }\n \n .preloader-main {\n  background: rgb(5, 5, 5);\n\n    position: fixed;\n    top: 0;\n    left: 0;\n    width:100%;\n    height:100%;\n    \n    display: flex;\n    align-items: center;\n    justify-content: center;\n \n    \n  }\n  \n  .preloader-main::after {\n    width: 120px;\n    height: 120px;\n    content: \"\";\n    transition: all 0.5s;\n    border-radius: 50%;\n    border: 4px solid var(--ITSports-color-green);\n    border-top-color: #000000;\n    \n    animation: loading 1s linear infinite;\n   \n  }\n  \n  @keyframes loading {\n    0% {\n      transform: rotate(0deg);\n    }\n    0% {\n      transform: rotate(-360deg);\n    }\n  }\n  \n\n\n   .preloader-bg.main-loaded_hiding{\n   opacity: 0;\n   transition: opacity 0.5s ;\n   pointer-events: none;\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
