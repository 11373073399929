import React, { useEffect, useState } from 'react'
import '../styles/OurAwesomeTeam.scss'
import SlideOurTeam from './SlideOurTeam';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Lazy } from 'swiper'
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";



// import required modules
import { Navigation } from "swiper";






// const imgs = [
//    'https://itsports.ru/static/assets/img/ivanov-goal-cska.gif',
// ]
// const slides = [
//    'https://itsports.ru/static/assets/img/evgeniyaprojectmenejer.mp4',
//    'https://itsports.ru/static/assets/img/dianaacauntmeneger.mp4',
//    'https://itsports.ru/static/assets/img/Fatima.mp4',
//    'https://itsports.ru/static/assets/img/Kristina.mp4',
//    'https://itsports.ru/static/assets/img/Itika.mp4',
//    'https://itsports.ru/static/assets/img/Hesel.mp4',
// ]




function OurTeam({lang,items,isTeamReady,played,setVidFlag,vidFlag}){
   
   // const teammates=[
   //    [
   //       'Евгения','Менеджер проектов','https://itsports.ru/static/assets/img/evgeniyaprojectmenejer_(1).mp4'
   //    ],
   //    [
   //       'Диана','Старший QA-engineer','https://itsports.ru/static/assets/img/dianaacauntmeneger_(1).mp4'
   //    ],
   //    [
   //       'Итика','UX-дизайнер','https://itsports.ru/static/assets/img/Itika_(1).mp4'
   //    ],
   //    [
   //       'Кристина','Аккаунт-менеджер','https://itsports.ru/static/assets/img/Kristina_(1).mp4'
   //    ],
   //    [
   //       'Хесель','Менеджер по персоналу','https://itsports.ru/static/assets/img/Hesel_(1).mp4'
   //    ],
   //    [
   //       'Фатима','Старший менджер проектов', 'https://itsports.ru/static/assets/img/Fatima_(1).mp4'
   //    ],
   
   
   
   //    [
   //       'Ахмед','Аккаунт-менеджер','https://itsports.ru/static/assets/img/Ахмед._Аккаунт-менеджер_(1).mp4'
   //    ],
   //    [
   //       'Герман','Директор по технологиям','https://itsports.ru/static/assets/img/Герман._Генеральный_директор_(1).mp4'
   //    ],
   //    [
   //       'Нахид','Старший аккаунт-менеджер','https://itsports.ru/static/assets/img/Нахид._Старший_аккаунт-менеджер_(1).mp4'
   //    ],
   //    [
   //       'Фарук','Аккаунт-менеджер','https://itsports.ru/static/assets/img/Фарук._Аккаунт-менеджер_(1).mp4'
   //    ],
   //    [
   //       'Фёдор','Frontend-разработчик','https://itsports.ru/static/assets/img/Фёдор._Frontend-разработчик_(1).mp4'
   //    ],
   
   
   
   //    [
   //       'Андрей','Backend-разработчик','https://itsports.ru/static/assets/img/Андрей._Backend-разработчик_(1).mp4'
   //    ],
   //    [
   //       'Денис','Frontend-разработчик','https://itsports.ru/static/assets/img/Денис._Frontend-разработчик_(1).mp4'
   //    ],
   //    [
   //       'Дмитрий','Backend-разработчик','https://itsports.ru/static/assets/img/Дмитрий_Java_Script_разработчик_(1).mp4'
   //    ],
   //    [
   //       'Дмитрий','Менеджер проектов','https://itsports.ru/static/assets/img/Дмитрий._Менеджер_проектов.mp4'
   //    ],
   //    [
   //       'Иван','Frontend-разработчик','https://itsports.ru/static/assets/img/Иван._Frounted-разработчик_(1).mp4'
   //    ],
   //    [
   //       'Анастасия','UX-дизайнер','https://itsports.ru/static/assets/img/Анастасия.mp4'
   //    ],
   //    [
   //       'Артемий','Android и IOS-разработчик','https://itsports.ru/static/assets/img/Артемий.mp4'
   //    ],
   //    [
   //       'Игорь','Backend-разработчик','https://itsports.ru/static/assets/img/Игорь._Backend-разрабочтик_(1).mp4'
   //    ],
   //    [
   //       'Константин','Tech Lead','https://itsports.ru/static/assets/img/Константин._Tech_Lead_(1).mp4'
   //    ],
   // ]
   // const mobTeam=[
   //    [
   //       'Евгения','Менеджер проектов','https://itsports.ru/static/assets/img/Евгения. Менеджер проектов.gif'
   //    ],
   //    [
   //       'Диана','Старший QA-engineer','https://itsports.ru/static/assets/img/Диана. Сарший QA-engineer.gif'
   //    ],
   //    [
   //       'Итика','UX-дизайнер','https://itsports.ru/static/assets/img/Итика. Менеджер по развитию бизнеса.gif'
   //    ],
   //    [
   //       'Кристина','QA-engineer','https://itsports.ru/static/assets/img/Кристина. QA - engineer.gif'
   //    ],
   //    [
   //       'Хесель','Менеджер по персоналу','https://itsports.ru/static/assets/img/Хесель. Менеджер по персоналу.gif'
   //    ],
   //    [
   //       'Фатима','Старший менджер проектов', 'https://itsports.ru/static/assets/img/Фатима. Менеджер проектов.gif'
   //    ],
   
   
   
   //    [
   //       'Ахмед','Аккаунт-менеджер','https://itsports.ru/static/assets/img/Ахмед. Аккаунт - менеджер .gif'
   //    ],
   //    [
   //       'Герман','Директор по технологиям','https://itsports.ru/static/assets/img/Герман. Генеральный директор.gif'
   //    ],
   //    [
   //       'Нахид','Старший аккаунт-менеджер','https://itsports.ru/static/assets/img/Нахид. Старший аккаунт-менеджер.gif'
   //    ],
   //    [
   //       'Фарук','QA-engineer','https://itsports.ru/static/assets/img/Фарук. QA-engineer.gif'
   //    ],
   //    [
   //       'Фёдор','Frontend-разработчик','https://itsports.ru/static/assets/img/Фёдор. Frontend-разработчик.gif'
   //    ],
   
   
   
   //    [
   //       'Андрей','Backend-разработчик','https://itsports.ru/static/assets/img/Андрей. Backend-разработчик.gif'
   //    ],
   //    [
   //       'Денис','Frontend-разработчик','https://itsports.ru/static/assets/img/Денис. Frontend-разработчик.gif'
   //    ],
   //    [
   //       'Дмитрий','Backend-разработчик','https://itsports.ru/static/assets/img/Дмитрий. Backend-разработчик.gif'
   //    ],
   //    [
   //       'Дмитрий','Менеджер проектов','https://itsports.ru/static/assets/img/Дмитрий. Менеджер проектов.gif'
   //    ],
   //    [
   //       'Иван','Frontend-разработчик','https://itsports.ru/static/assets/img/Иван. Frounted-разработчик.gif'
   //    ],
   //    [
   //       'Анастасия','UX-дизайнер','https://itsports.ru/static/assets/img/Анастасия. UX-дизайнер.gif'
   //    ],
   //    [
   //       'Артемий','Android и IOS-разработчик','https://itsports.ru/static/assets/img/Артемий. Android и IOS - разработчик.gif'
   //    ],
   //    [
   //       'Игорь','Backend-разработчик','https://itsports.ru/static/assets/img/Игорь. Backend-разрабочтик.gif'
   //    ],
   //    [
   //       'Константин','Tech Lead','https://itsports.ru/static/assets/img/Константин. Tech Lead.gif'
   //    ],
   // ]

   // const mobTeamEn=[
   //    [
   //       'Evgeniya','Project-manager','https://itsports.ru/static/assets/img/Евгения. Менеджер проектов.gif'
   //    ],
   //    [
   //       'Diana','Main QA-engineer','https://itsports.ru/static/assets/img/Диана. Сарший QA-engineer.gif'
   //    ],
   //    [
   //       'Itika','UX-designer','https://itsports.ru/static/assets/img/Итика. Менеджер по развитию бизнеса.gif'
   //    ],
   //    [
   //       'Kristina','QA-engineer','https://itsports.ru/static/assets/img/Кристина. QA - engineer.gif'
   //    ],
   //    [
   //       'Hesel','HR manager','https://itsports.ru/static/assets/img/Хесель. Менеджер по персоналу.gif'
   //    ],
   //    [
   //       'Fatima','Main Project-manager', 'https://itsports.ru/static/assets/img/Фатима. Менеджер проектов.gif'
   //    ],
   
   
   
   //    [
   //       'Ahmed','Account-manager','https://itsports.ru/static/assets/img/Ахмед. Аккаунт - менеджер .gif'
   //    ],
   //    [
   //       'German','Technology Director','https://itsports.ru/static/assets/img/Герман. Генеральный директор.gif'
   //    ],
   //    [
   //       'Nahid','Main account-manager','https://itsports.ru/static/assets/img/Нахид. Старший аккаунт-менеджер.gif'
   //    ],
   //    [
   //       'Faruk','QA-engineer','https://itsports.ru/static/assets/img/Фарук. QA-engineer.gif'
   //    ],
   //    [
   //       'Fedor','Frontend-developer','https://itsports.ru/static/assets/img/Фёдор. Frontend-разработчик.gif'
   //    ],
   
   
   
   //    [
   //       'Andrey','Backend-developer','https://itsports.ru/static/assets/img/Андрей. Backend-разработчик.gif'
   //    ],
   //    [
   //       'Denis','Frontend-developer','https://itsports.ru/static/assets/img/Денис. Frontend-разработчик.gif'
   //    ],
   //    [
   //       'Dmitriy','Backend-developer','https://itsports.ru/static/assets/img/Дмитрий. Backend-разработчик.gif'
   //    ],
   //    [
   //       'Dmitriy','Project-manager','https://itsports.ru/static/assets/img/Дмитрий. Менеджер проектов.gif'
   //    ],
   //    [
   //       'Inan','Frontend-developer','https://itsports.ru/static/assets/img/Иван. Frounted-разработчик.gif'
   //    ],
   //    [
   //       'Anastasia','UX-designer','https://itsports.ru/static/assets/img/Анастасия. UX-дизайнер.gif'
   //    ],
   //    [
   //       'Artemiy','Android/IOS-developer','https://itsports.ru/static/assets/img/Артемий. Android и IOS - разработчик.gif'
   //    ],
   //    [
   //       'Igor','Backend-developer','https://itsports.ru/static/assets/img/Игорь. Backend-разрабочтик.gif'
   //    ],
   //    [
   //       'Konstantin','Tech Lead','https://itsports.ru/static/assets/img/Константин. Tech Lead.gif'
   //    ],
   // ]
   // const teammatesEn=[
   //    [
   //       'Evgeniya','Project-manager','https://itsports.ru/static/assets/img/evgeniyaprojectmenejer_(1).mp4'
   //    ],
   //    [
   //       'Diana','Main QA-engineer','https://itsports.ru/static/assets/img/dianaacauntmeneger_(1).mp4'
   //    ],
   //    [
   //       'Itika','UX-designer','https://itsports.ru/static/assets/img/Itika_(1).mp4'
   //    ],
   //    [
   //       'Kristina','QA-engineer','https://itsports.ru/static/assets/img/Kristina_(1).mp4'
   //    ],
   //    [
   //       'Hesel','HR manager','https://itsports.ru/static/assets/img/Hesel_(1).mp4'
   //    ],
   //    [
   //       'Fatima','Main Project-manager', 'https://itsports.ru/static/assets/img/Fatima_(1).mp4'
   //    ],
   
   
   
   //    [
   //       'Ahmed','Account-manager','https://itsports.ru/static/assets/img/Ахмед._Аккаунт-менеджер_(1).mp4'
   //    ],
   //    [
   //       'German','Technology Director','https://itsports.ru/static/assets/img/Герман._Генеральный_директор_(1).mp4'
   //    ],
   //    [
   //       'Nahid','Main account-manager','https://itsports.ru/static/assets/img/Нахид._Старший_аккаунт-менеджер_(1).mp4'
   //    ],
   //    [
   //       'Faruk','QA-engineer','https://itsports.ru/static/assets/img/Фарук._Аккаунт-менеджер_(1).mp4'
   //    ],
   //    [
   //       'Fedor','Frontend-developer','https://itsports.ru/static/assets/img/Фёдор._Frontend-разработчик_(1).mp4'
   //    ],
   
   
   
   //    [
   //       'Andrey','Backend-developer','https://itsports.ru/static/assets/img/Андрей._Backend-разработчик_(1).mp4'
   //    ],
   //    [
   //       'Denis','Frontend-developer','https://itsports.ru/static/assets/img/Денис._Frontend-разработчик_(1).mp4'
   //    ],
   //    [
   //       'Dmitriy','Backend-developer','https://itsports.ru/static/assets/img/Дмитрий_Java_Script_разработчик_(1).mp4'
   //    ],
   //    [
   //       'Dmitriy','Project-manager','https://itsports.ru/static/assets/img/Дмитрий._Менеджер_проектов.mp4'
   //    ],
   //    [
   //       'Inan','Frontend-developer','https://itsports.ru/static/assets/img/Иван._Frounted-разработчик_(1).mp4'
   //    ],
   //    [
   //       'Anastasia','UX-designer','https://itsports.ru/static/assets/img/Анастасия.mp4'
   //    ],
   //    [
   //       'Artemiy','Android/IOS-developer','https://itsports.ru/static/assets/img/Артемий.mp4'
   //    ],
   //    [
   //       'Igor','Backend-developer','https://itsports.ru/static/assets/img/Игорь._Backend-разрабочтик_(1).mp4'
   //    ],
   //    [
   //       'Konstantin','Tech Lead','https://itsports.ru/static/assets/img/Константин._Tech_Lead_(1).mp4'
   //    ],
   // ]
   

   // let teamComp= lang=='ru' ? teammates : teammatesEn
   // let teamMob= lang=='ru' ? mobTeam : mobTeamEn

   //const [video] = useState(slides)
   // function Swiper() {
   //    return (
   //       <>
   //          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
   //          <SwiperSlide>Slide 1</SwiperSlide>
   //          <SwiperSlide>Slide 2</SwiperSlide>
   //          <SwiperSlide>Slide 3</SwiperSlide>
   //          <SwiperSlide>Slide 4</SwiperSlide>
   //          <SwiperSlide>Slide 5</SwiperSlide>
   //          <SwiperSlide>Slide 6</SwiperSlide>
   //          <SwiperSlide>Slide 7</SwiperSlide>
   //          <SwiperSlide>Slide 8</SwiperSlide>
   //          <SwiperSlide>Slide 9</SwiperSlide>
   //          </Swiper>
   //       </>
   //    );
   // }
   // let noactiveSliders
   // useEffect(()=>{
   //    let func = ()=>{
   //          //let sliders = document.querySelectorAll('.swiper-slider-team')



   //          function playVidos(){
   //             //let activeSliders =document.querySelectorAll('.swiper-slider-team.swiper-slide-active')

               
   //                noactiveSliders = document.querySelectorAll('.swiper-slider-team')
                  
   //                noactiveSliders.forEach(slide=>{
   //                   if(slide.classList.contains('swiper-slide-active')){
   //                      let vid=slide.querySelector('.team-slider__vidos')
                     
   //                      vid.classList.add('active-auto')

   //                      if(vid.tagName!=='IMG'){
   //                         vid.play()
   //                      }
                        
   //                   }else{
   //                      let vid=slide.querySelector('.team-slider__vidos')
                     
   //                      vid.classList.remove('active-auto')
   //                      if(vid.tagName!=='IMG'){
   //                         vid.pause()
   //                      }
                        
   //                   }
   //                })
               
            
   //             // activeSliders.forEach(el=>{
   //             //    let vid=el.querySelector('.team-slider__vidos')
                  
   //             //    vid.classList.add('active-auto')
   //             //    vid.play()
   //             // })
               
               
   //          }
            
               
   //             setInterval(()=>{
   //                playVidos()
   //             },1000)
            
            
            
   //          //for(let i in sliders){
               
   //             // if(sliders[i].classList.contains('swiper-slide-active')){
               
   //             //    let vid=sliders[i].querySelector('.team-slider__vidos')
   //             //    vid.classList.toggle('active-auto')
   //             //    vid.play()
                  
   //             // }
            
            
   //          //}
               
            
   //          // let btnNext=document.querySelector('.swiper-button-next.team')
   //          // let btnPrev=document.querySelector('.swiper-button-prev.team')

   //          // btnNext.addEventListener('click',()=>{
   //          //    handlclick()
   //          // })
   //          // btnPrev.addEventListener('click',()=>{
   //          //    handlclick()
   //          // })


   //          // let handlclick=()=>{
   //          //    document.querySelector('.active-auto').pause()
   //          //    document.querySelector('.active-auto').classList.remove('active-auto')

   //          //    //let sliders = document.querySelectorAll('.swiper-slider-team')

   //          // // for(let i in sliders){


   //          // //       if(sliders[i].classList.contains('swiper-slide-active')){
                     
   //          // //          let vid=sliders[i].querySelector('.team-slider__vidos')
   //          // //          vid.classList.toggle('active-auto')
   //          // //          vid.play()
   //          // //          break
   //          // //       }
   //          // //    }
   //          // }
   //    }
   //    func()


      
   // },[])
   const [testL,setTestL]=useState(false)
   const changeTestL=()=>{
      setTimeout(() => {
         setTestL(true)
      }, 500);
      
      console.log('imload')
      //setImg(0)
   }
   const [slides,setSlides]=useState(false)
   const changeSlide=()=>{
      setSlides(true)
      console.log('d')
      //setImg(0)
   }

   useEffect(()=>{

         function playVidos(){
            //let activeSliders =document.querySelectorAll('.swiper-slider-team.swiper-slide-active')

            
               let noactiveSliders = document.querySelectorAll('.swiper-slider-team')
               
               noactiveSliders.forEach(slide=>{

                  slide.querySelector('.team-slider__vidos').setAttribute('muted','muted')

                  if(slide.classList.contains('swiper-slide-active')){
                     let vid=slide.querySelector('.team-slider__vidos')
                     var isPlaying = vid.currentTime > 0 && !vid.paused && !vid.ended && vid.readyState > vid.HAVE_CURRENT_DATA;
                     vid.classList.add('active-auto')

                     if(vid.tagName!=='IMG'&&!isPlaying){
                        vid.play()
                        //console.log(vid)
                     }
                     
                  }else{
                     let vid=slide.querySelector('.team-slider__vidos')
                  
                     vid.classList.remove('active-auto')
                     if(vid.tagName!=='IMG'){
                        vid.pause();
                     
                     }
                     
                  }
               })
            
         
            // activeSliders.forEach(el=>{
            //    let vid=el.querySelector('.team-slider__vidos')
               
            //    vid.classList.add('active-auto')
            //    vid.play()
            // })
            
            
         }
         
            
            let intr=setInterval(()=>{
               playVidos()
               
            },1000)
            return ()=>{clearInterval(intr)}
         

   },[vidFlag])
   const [img,setImg]=useState(null)
   useEffect(()=>{
      //console.log(slides)
      if(testL){
         if(slides){
            console.log('0000')
            setImg(0)
         }else{
            let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
            console.log('1111')
            if (isIOS) {
               console.log('11222')
               setImg(1)
            } else {
               console.log('11333')
               setImg(0)
            }
         }
      }
      
         
      
         
   

   },[slides,testL])

   let componentItems = items.data.attributes.team
   
   // https://itsports.ru/app/uploads/Fyodor_Frontend_razrabotchik_1_0402eb222d.mp4?updated_at=2022-07-27T14:08:11.682Z
      return (
         <div id='team' className="ourAwesomeTeam">

               <video  onLoadedData={changeTestL}  onPlay={changeSlide} className="testvideo "  preload='auto' autoPlay={true} muted loop playsInline>
                  <source  src='https://itsports.ru/app/uploads/Fyodor_Frontend_razrabotchik_1_0402eb222d.mp4?updated_at=2022-07-27T14:08:11.682Z' type='video/mp4'/>
               </video> 
            {
               (isTeamReady)?<div>
               
               
            
               <div className="ourAwesomeTeam__title">{componentItems.title}</div>
               
               <div className="ourAwesomeTeam__slider team-slider" >
               {img!==null&&
                  <div className="team-slider__vidos-container" >
                     {/* <video className="team-slider__vidos active-teammate" autoPlay muted="muted" loop><source src={video[0]} type='video/mp4'/></video>            
                     <video className="team-slider__vidos next-teammate" autoPlay muted="muted" loop><source src={video[1]} type='video/mp4'/></video>             */}
                        
                        <Swiper 
                           
                           slidesPerView={3}
                           spaceBetween={2}
                           centeredSlides ={true}
                           loop={true}
                           loopFillGroupWithBlank={true}
                           navigation={{
                              nextEl: '.swiper-button-next.team',
                              prevEl: '.swiper-button-prev.team',}
                           } 
                           modules={[Navigation]} 
                           breakpoints={{
                              1134: {
                              slidesPerView: 5,
                           },
                           }}
                           
                           className="team-swiper five-teammates">
      
                           <div className="swiper-button-prev team"></div>
                           <div className="swiper-button-next team"></div>
      
                           {
                              componentItems.slide.map(item=>(
      
                                 <SwiperSlide  key={item.id} className='swiper-slider-team'>
                                       
                                       <SlideOurTeam played={false} item={item} img={img}/>
                                    
                                    
                                 </SwiperSlide>
                              ))
                           }
                           
                        </Swiper>
                        
                        
                        <Swiper 
                           
                           modules={[Navigation,Lazy]} 
                           slidesPerView={1}
                           spaceBetween={0}
                           centeredSlides ={true}
                           loop={true}
                           preloadImages={false}
                           lazy={true}
                           loopFillGroupWithBlank={true}
                           navigation={{
                              nextEl: '.swiper-button-next.team',
                              prevEl: '.swiper-button-prev.team',}
                           } 
                        
                           className="team-swiper one-teammates">
      
                           <div className="swiper-button-prev team"></div>
                           <div className="swiper-button-next team"></div>
                           {
                              componentItems.slide.map(item=>(
      
                                 <SwiperSlide  key={item.id} className='swiper-slider-team'>
                                       
                                       <SlideOurTeam played={false} item={item} img={img}/>
                                    
                                    
                                 </SwiperSlide>
                              ))
                           }
                           
                           
                        </Swiper>
                        



                        {/* <Swiper 
                           
                           slidesPerView={3}
                           spaceBetween={0}
                           centeredSlides ={true}
                           loop={true}
                           loopFillGroupWithBlank={true}
                           navigation={{
                              nextEl: '.swiper-button-next.team',
                              prevEl: '.swiper-button-prev.team',}
                           } 
                           modules={[Navigation]} 
                           className="team-swiper three-teammates">
      
                           <div className="swiper-button-prev team"></div>
                           <div className="swiper-button-next team"></div>
                           {
                              componentItems.slide.map(item=>(
      
                                 <SwiperSlide  key={item.id} className='swiper-slider-team'>
                                       
                                       <SlideOurTeam played={played} item={item} img={0}/>
                                    
                                    
                                 </SwiperSlide>
                              ))
                           }
                           
                        </Swiper> */}
                  </div>
               }
               </div>
            </div>
            :
            <div>loading...</div>
         }
         </div>
         )
   }
   




export default OurTeam