import React, { useEffect, useState } from 'react'
import { act } from 'react-dom/test-utils'
import Accordion from '../helpers/Accordion'
import vacancyData from '../helpers/vacancyData'
import '../styles/Vacancy.scss'
import Carret from '../helpers/carret'

function Vacancy({lang,items}) {
    //svg.classList.toggle('active-svg-arrow')
    let arrow 
    useEffect(()=>{
        arrow = document.querySelectorAll('.vacancy__tabs__item__header')
        let arClick=(ar)=>{
            let svg = ar.querySelector('svg')
            
            svg.classList.toggle('active-svg-arrow')
            let height= 41+ ar.clientHeight
            let tab = ar.closest('.vacancy__tabs__item')
            
            tab.style.height=height+`px`

            setTimeout(()=>{
                    let tabActive=tab.querySelector('.vacancy__tabs__item--active.activet')
                    if(tabActive){
                        

                        tab.style.height=height+tabActive.clientHeight+`px`
                        
                    }
                    
                
            },50)
        }

        //let tabs=document.querySelectorAll('.vacancy__tabs__item')
        //const svg = arrow.querySelector('svg')
        arrow.forEach(ar=>{
            ar.addEventListener('click',()=>arClick(ar))
        })


        
    },[arrow])

    //let componentItems = items.data.attributes.vacancies
    let componentItems = items.data.attributes.newvacancies
    
    return (
        <div id='vacancies' className='container'>
            <div className="vacancy">
                <h1 className="vacancy__title">{componentItems.title}</h1>
                <div className="vacancy__tabs">

                {/* {
                    React.Children.toArray(componentItems.vacancie.map((item, index) => (
                        <Accordion
                            componentItems={componentItems}
                            lang={lang}
                            title={item.title}
                            subtitle={item.subtitle}
                            titleEn={item.titleEn}
                            subtitleEn={item.subtitleEn}
                            inOfficeOnly={item.inOfficeOnly}
                            responsibility={item.responsibility}
                            responsibilityEn={item.responsibilityEn}
                            requirements={item.requirements}
                            terms={item.terms}
                            requirementsEn={item.requirementsEn}
                            termsEn={item.termsEn}
                            index={index}
                        />
                    )))
                } */}
                    {
                        componentItems.vacancy.map((item, index)=>(
                            <div key={index}>
                                <a target='_blank' href={item.link} className="vacancy__tabs__item__header--title vcTborder">
                                    
                                    <h2>{item.name}</h2>
                                    <div className='svgcnt'><Carret/></div>
                                    

                                </a>

                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Vacancy