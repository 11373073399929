// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dragn {
  width: 100%;
  height: 185px;
  padding-bottom: 32px;
  cursor: pointer;
}

.drop-area {
  text-align: center;
  height: 100%;
  width: 100%;
  border: 2px dashed black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  z-index: 999999999999;
  transition: all 0.2s;
}
.drop-area.end-drop {
  background-color: rgba(0, 0, 0, 0.151);
}

.form-vacancy__container .drop-area {
  border: 2px dashed white;
}
.form-vacancy__container .drop-area.end-drop {
  background-color: rgba(218, 218, 218, 0.515);
}`, "",{"version":3,"sources":["webpack://./src/styles/Dragndrop.scss"],"names":[],"mappings":"AACA;EACG,WAAA;EACA,aAAA;EACA,oBAAA;EACA,eAAA;AAAH;;AAEA;EAEG,kBAAA;EACA,YAAA;EACA,WAAA;EACA,wBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,qBAAA;EAEA,oBAAA;AADH;AAEG;EACG,sCAAA;AAAN;;AAKG;EACG,wBAAA;AAFN;AAGM;EACG,4CAAA;AADT","sourcesContent":["@import 'common/variables.scss';\n.dragn{\n   width: 100%;\n   height: 185px;\n   padding-bottom: 32px;\n   cursor: pointer;\n}\n.drop-area{\n   \n   text-align: center;\n   height: 100%;\n   width: 100%;\n   border: 2px dashed black;\n   display: flex;\n   align-items: center;\n   justify-content: center;\n   font-size: 20px;\n   z-index: 999999999999;\n   \n   transition: all 0.2s;\n   &.end-drop{\n      background-color: rgba(0, 0, 0, 0.151);\n   }\n}\n\n.form-vacancy__container{\n   .drop-area{\n      border: 2px dashed white;\n      &.end-drop{\n         background-color: rgba(218, 218, 218, 0.515);\n      }\n   }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
