import React, { useState,useRef } from 'react'
import '../styles/Form.scss'
import Dragndrop from './Dragndrop'
function Form({lang,items}) {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [isDrop, setDrop] = useState(false)
    const [file, setFile]=useState(false)
    const fileRef = useRef(null)
    //console.log(fileRef.current?.value,fileRef.current?.files,typeof(fileRef.current?.files))
    let updateDrop = () => {
        setDrop(prevState => !prevState)
        
    }
    const handleOnChange = (event) => {
        const inputType = event.target.getAttribute('name')
        switch (inputType) {
            case "name":
                setName(event.target.value)
                break;
            case "phone":
                setPhone(event.target.value)
                break;
            default:
                setEmail(event.target.value)
                break;
        }
    }
//method='POST' action='https://itsports.ru/newITS/php/indexForm.php'
    return (
        <div className="container">
            <div className='form' >


                <h1 className="form__title">
                    {lang=='ru'?'Рассчитаем Ваш проект':'We will calculate'}
                    <br/>
                        {lang=='ru'?'и подготовим коммерческое':'your project and prepare'}
                    <br/>
                    {lang=='ru'?'предложение за 3 дня':'a commercial offer in 3 days'}
                    
                </h1>
                <form  
                method='POST' action='https://jsonb.ru/indexForm.php' encType="multipart/form-data"
                className='form__container' >
                    <input
                    required
                        value={name}
                        onChange={handleOnChange}
                        placeholder={lang=='ru'?'Имя':'Name'}
                        className='form__input'
                        type="text"
                        name="name"
                    />
                    <input
                    required
                        value={phone}
                        onChange={handleOnChange}
                        placeholder='+7(999) 999-99-99'
                        className='form__input'
                        type="tel"
                        name='phone'
                    />
                    <input
                    required
                        value={email}
                        onChange={handleOnChange}
                        placeholder='E-mail'
                        className='form__input'
                        type="email"
                        name='email'
                    />
                    <textarea
                    required
                        className='form__input form__textarea'
                        name="message"
                        id="message"
                        cols="30"
                        rows="5"
                        placeholder={lang=='ru'?'Сообщение':'Message'}
                    />
                    <div className='form__container-file-input'>
                        <input  className='form__file-input'
                            type="file"
                            name='file'
                            style={{display:isDrop ? 'block' : 'none'}}
                            onChange={()=>setFile(true)}
                            ref={fileRef}
                            onMouseOut={updateDrop}
                        />
                        <Dragndrop fileRef={fileRef.current} file={file} setFile={setFile} isDrop={isDrop} updateDrop={updateDrop} lang={lang}/>
                    </div>

                    <button className='form__button' type='submit'>{lang=='ru'?'отправить':'SEND'}</button>
                </form>
            </div>
        </div>
    )
}

export default Form