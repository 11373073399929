


let getFetch=(fethUrl,error,setError,isLoaded,setIsLoaded,items,setItems)=>{

   fetch(fethUrl)
      .then(res => res.json())
      .then(
         (result) => {
            setIsLoaded(true);
            setItems(result);
            //console.log(result.data.attributes)
         },
         (error) => {
            setIsLoaded(true);
            setError(error);
            console.log(error)
         }
      )

      return error,isLoaded,items
}

export default getFetch 