

const vacancyData = [
    {
        title: 'project-manager',
        subtitle: ``,
        titleEn: 'project-manager',
        subtitleEn: ``,
        inOfficeOnly: true,
        responsibility: [
            `Взаимодействие с разработчиком, тестирование реализованного функционала на соответствие требованиям`,
            `Демонстрация готового функционала заказчикам, сбор и анализ обратной связи`,
            `Консультирование по особенностям работы созданных продуктов`,
            `Написание пользовательской документации и поддержание в актуальном состоянии рабочих инструкций`,
            `Работа с загрузкой приложений Google Play/App Store`,
            'Поиск решений'
        ],
        requirements: [
            `Хороший английский язык`,
            `Умение говорить на одном языке с IT-отделом и заказчиками`,
            `Поддерживать и развивать текущие проекты`,
            `Способность работать в высоком темпе, многозадачность, самостоятельность`
        ],
        terms: [
            `Конкурентная заработная плата. Бонусы при выполнении KPI, 13-я заработная плата`,
            `Официальное трудоустройство`,
            `100% оплата больничного`,
            `Обучение за счет компании, компенсация спортзала, программа адаптации и введения в должность, участие в семинарах и конференциях (после испытательного срока)`,
            `Возможность приобрести знания и уникальный опыт работы в команде профессионалов и развить профессиональные компетенции в сфере IT`,
            `Комфортный офис (м. Сходненская / МЦД Трикотажная) и рабочая среда`,
        ],
        responsibilityEn: [
            `Teamwork with the developers, testing the implemented functionality for compliance with the requirements`,
            `Demonstration of the finished functionality to customers, collection and analysis of feedback`,
            `Consulting on the features of the created products`,
            `Writing user documentation and keeping up-to-date operating procedures`,
            `Work with downloading Google Play / App Store applications`,
            'Searching of decisions'
        ],
        requirementsEn: [
            `Good English`,
            `Ability to speak in same language with the IT-department and customers`,
            `Support and develop current projects`,
            `Ability to work at a fast pace, multitasking, independent`
        ],
        termsEn: [
            `Competitive salary. Bonuses for fulfilling KPI, 13th salary`,
            `Official employment`,
            `100% sick pay`,
            `Company sponsored training, gym compensation, adaptation and induction program, adaptation and induction program, participation in seminars and conferences (after a probationary period)`,
            `The opportunity to gain knowledge and unique experience of working in a team of professionals and develop professional competencies in the IT field`,
            `Comfortable office (metro station Skhodnenskaya / MCD Trikotazhnaya)`,
        ],
    },

    
    {
        title: 'менеджер по интернет-маркетингу',
        subtitle: `(Со знанием узбекского языка)`,
        titleEn: 'Internet Marketing Manager',
        subtitleEn: `(With knowledge of the Uzbek language)`,
        inOfficeOnly: true,
        responsibility: [
            `Поиск и привлечение партнёров, поддержание и развитие долгосрочных взаимовыгодных партнёрских отношений`,
            `Ведение сделки с нуля до заключения контракта`,
            `Анализ трафика интернет-ресурсов`,
            `Покупка рекламы в социальных сетях и на сайтах`
        ],
        requirements: [
            `Желание работать и развиваться в крупной международной компании`,
            `Мотивированность в материальном плане`,
            `Знание английского на уровне не ниже Intermediate`,
            `Знание узбекского языка`,
            `Знание других иностранных языков также приветствуется`,
            `Опыт работы маркетологом желателен`
        ],
        terms: [
            `Конкурентоспособная заработная плата от 50 000 р.+ премии по результатам KPI и 13-я заработная плата`,
            `Официальное трудоустройство`,
            `Компенсация спортзала, и различных курсов, необходимых для профессионального роста (после испытательного срока)`,
            `Свобода и ресурсы для реализации задач`,
            `100% оплата больничного`,
            `Комфортный офис`,
            `Оплачиваемая стажировка в течение 1 месяца`
        ],
        responsibilityEn: [
            `Searching and Attracting of partners, maintenance and development of long-term mutually beneficial partnerships`,
            `Conducting a deal from scratch to the conclusion of a contract`,
            `Internet resource traffic analysis`,
            `Buying ads on social networks and websites`,
        ],
        requirementsEn: [
            `Desire to work and develop in a big international company`,
            `Financial motivation`,
            `Knowledge of English in  Intermediate level`,
            `Knowledge of the Uzbek language`,
            `Knowledge of other foreign languages ​​is welcome`,
            `Experience in marketing preferred`
        ],
        termsEn: [
            `Competitive salary from 50,000 rubles + bonuses based on KPI, 13th salary;`,
            `Official employment`,
            `Gym compensation, and courses necessary for professional growth (after the probationary period)`,
            `Freedom and resources for the implementation of tasks`,
            `100% sick pay`,
            `Comfortable office;`,
            `Paid internship for 1 month`
        ],
    },
    {
        title: 'Менеджер по работе с партнерами',
        subtitle: `(Со знанием английского языка)`,
        titleEn: 'Partner Relations Manager ',
        subtitleEn: `(With knowledge of  English language)`,
        inOfficeOnly: true,
        responsibility: [
            `Общение с партнерами компании в странах СНГ, Европы и Азии`,
            `Поиск, ведение и контроль работы партнеров`,
            `Формирование ежемесячных отчетов`,
            `Взаимодействие с партнерами на всех этапах`,
            'Выстраивание доверительных отношений с максимальным количеством партнеров'
        ],
        requirements: [
            `Оконченное высшее образование`,
            `Знание английского на уровне не ниже Pre-Intermediate`,
            `Знание других иностранных языков приветствуется`,
        ],
        terms: [
            `Конкурентная заработная плата. Бонусы при выполнении KPI, 13-я заработная плата`,
            `Официальное трудоустройство`,
            `100% оплата больничного`,
            `Обучение за счет компании, компенсация спортзала, программа адаптации и введения в должность, участие в семинарах и конференциях (после испытательного срока)`,
            `Возможность приобрести знания и уникальный опыт работы в команде профессионалов и развить профессиональные компетенции в сфере IT`,
            `Комфортный офис (м. Сходненская / МЦД Трикотажная) и рабочая среда`
        ],
        responsibilityEn: [
            `Communication with partners from the CIS countries, Europe and Asia`,
            `Searching, maintenance and control of the partners work`,
            `Preparation of monthly reports`,
            `Interaction with partners at all stages`,
            `Building trusting relationships with the maximum number of partners`,
        ],
        requirementsEn: [
            `Completed higher education`,
            `Knowledge of English at a level not lower than Pre-Intermediate`,
            `Knowledge of other foreign languages ​​is welcome`,
        ],
        termsEn: [
            `Competitive salary. Bonuses for fulfilling KPI, 13th salary`,
            `Official employment`,
            `100% sick pay`,
            `Company sponsored training, gym compensation, adaptation and induction program, adaptation and induction program, participation in seminars and conferences (after a probationary period)`,
            `The opportunity to gain knowledge and unique experience of working in a team of professionals and develop professional competencies in the IT field`,
            `Comfortable office (metro station Skhodnenskaya / MCD Trikotazhnaya)`
        ],
    },
    {
        title: 'business development manager',
        subtitle: `(Somaliai Mass Media and Social Networks)`,
        titleEn: 'business development manager',
        subtitleEn: `(Somaliai Mass Media and Social Networks)`,
        inOfficeOnly: false,
        responsibility: [
            `To find the B2B partners, to maintain and develop long-term mutually beneficial partnership`,
            `To execute the trades from scratch to concluding contracts`,
            `To analyze traffic of Internet resources`,
            `To buy social and mass media ads`
        ],
        requirements: [
            `Willing to work and grow professionally in a big international company`,
            `Monetary motivation`,
            `Intermediate level of English`,
            `Knowledge of Arabic (basic), English (basic) and Somali (Proficiency) ​​IS REQUIRED`,
            `Experience in the marketing area is desirable`,
            `Be able to organize special projects and events`
        ],
        terms: [
            `Stable salary`,
            `Official employment and full benefits package`,
            `Comfortable office`,
            `13th salary and regular bonuses`,
            `Paid conferences, trainings, and workshops (after the end of the trial period)`,
            `100% sickness benefit`,
            `Salary of 45,000 rub or more`,
            `Paid internship for 1 month in the office IS REQUIRED`
        ],
        responsibilityEn: [
            `To find the B2B partners, to maintain and develop long-term mutually beneficial partnership`,
            `To execute the trades from scratch to concluding contracts`,
            `To analyze traffic of Internet resources`,
            `To buy social and mass media ads`
        ],
        requirementsEn: [
            `Willing to work and grow professionally in a big international company`,
            `Monetary motivation`,
            `Intermediate level of English`,
            `Knowledge of Arabic (basic), English (basic) and Somali (Proficiency) ​​IS REQUIRED`,
            `Experience in the marketing area is desirable`,
            `Be able to organize special projects and events`
        ],
        termsEn: [
            `Stable salary`,
            `Official employment and full benefits package`,
            `Comfortable office`,
            `13th salary and regular bonuses`,
            `Paid conferences, trainings, and workshops (after the end of the trial period)`,
            `100% sickness benefit`,
            `Salary of 45,000 rub or more`,
            `Paid internship for 1 month in the office IS REQUIRED`
        ],
    },
    {
        title: 'business development manager',
        subtitle: `(Bangladeshi Mass Media and Social Networks)`,
        titleEn: 'business development manager',
        subtitleEn: `(Bangladeshi Mass Media and Social Networks)`,
        inOfficeOnly: false,
        responsibility: [
            `To find the B2B partners, to maintain and develop long-term mutually beneficial partnership`,
            `To execute the trades from scratch to concluding contracts`,
            `To analyze traffic of Internet resources`,
            `To buy social and mass media ads`
        ],
        requirements: [
            `Willing to work and grow professionally in a big international company`,
            `Monetary motivation`,
            `Intermediate level of English`,
            `Knowledge of Bengali IS REQUIRED`,
            `Experience in the marketing area is desirable`,
            `Attention to details, proactivity`
        ],
        terms: [
            `Competitive salary + KPIs performance related bonuses and 13th salary`,
            `Official / contract employment`,
            `Comfortable office`,
            `100% sickness benefit`,
            `Paid conferences, trainings, and workshops (after the end of the trial period)`,
            `Free exercise classes (fitsess)`,
            `Coffee, tea and various sweets are available free in our office`,
            `Paid internship for 1 month in the office IS REQUIRED`
        ],
        responsibilityEn: [
            `To find the B2B partners, to maintain and develop long-term mutually beneficial partnership`,
            `To execute the trades from scratch to concluding contracts`,
            `To analyze traffic of Internet resources`,
            `To buy social and mass media ads`
        ],
        requirementsEn: [
            `Willing to work and grow professionally in a big international company`,
            `Monetary motivation`,
            `Intermediate level of English`,
            `Knowledge of Bengali IS REQUIRED`,
            `Experience in the marketing area is desirable`,
            `Attention to details, proactivity`
        ],
        termsEn: [
            `Competitive salary + KPIs performance related bonuses and 13th salary`,
            `Official / contract employment`,
            `Comfortable office`,
            `100% sickness benefit`,
            `Paid conferences, trainings, and workshops (after the end of the trial period)`,
            `Free exercise classes (fitsess)`,
            `Coffee, tea and various sweets are available free in our office`,
            `Paid internship for 1 month in the office IS REQUIRED`
        ],
    },
    {
        title: 'business development manager',
        subtitle: `(Swahili Mass Media and Social Networks)`,
        titleEn: 'business development manager',
        subtitleEn: `(Swahili Mass Media and Social Networks)`,
        inOfficeOnly: false,
        responsibility: [
            `To find the B2B partners, to maintain and develop long-term mutually beneficial partnership`,
            `To execute the trades from scratch to concluding contracts`,
            `To analyze traffic of Internet resources`,
            `To buy social and mass media ads`
        ],
        requirements: [
            `Willing to work and grow professionally in a big international company`,
            `Monetary motivation`,
            `Intermediate level of English`,
            `Knowledge of Bengali IS REQUIRED`,
            `Experience in the marketing area is desirable`,
            `Attention to details, proactivity`
        ],
        terms: [
            `Stable salary`,
            `Official employment and full benefits package`,
            `Comfortable office`,
            `13th salary and regular bonuses`,
            `Paid conferences, trainings, and workshops (after the end of the trial period)`,
            `100% sickness benefit`,
            `Salary of 45,000 rub or more`,
            `Paid internship for 1 month in the office IS REQUIRED`
        ],
        responsibilityEn: [
            `To find the B2B partners, to maintain and develop long-term mutually beneficial partnership`,
            `To execute the trades from scratch to concluding contracts`,
            `To analyze traffic of Internet resources`,
            `To buy social and mass media ads`
        ],
        requirementsEn: [
            `Willing to work and grow professionally in a big international company`,
            `Monetary motivation`,
            `Intermediate level of English`,
            `Knowledge of Bengali IS REQUIRED`,
            `Experience in the marketing area is desirable`,
            `Attention to details, proactivity`
        ],
        termsEn: [
            `Stable salary`,
            `Official employment and full benefits package`,
            `Comfortable office`,
            `13th salary and regular bonuses`,
            `Paid conferences, trainings, and workshops (after the end of the trial period)`,
            `100% sickness benefit`,
            `Salary of 45,000 rub or more`,
            `Paid internship for 1 month in the office IS REQUIRED`
        ],
    }
]

export default vacancyData