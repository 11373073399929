import React from 'react'
import WhyUs from './WhyUs';
import About from './About';
import OutStack from './OurStack';
import Directions from './Directions';
import Form from './Form';
import Vacancy from './Vacancy';
import Contacts from './Contacts';
import Footer from './Footer';
import AAstart from './AAstart';
import Header from './Header';
import OurTeam from './OurAwesomeTeam';
import NewAbout from './Newabout';
import Confa from './Confa';
import { useState,useEffect } from 'react';
const Main = ({toggleLang,items,teamStart,isTeamReady,played,setPlayed}) => {
  const [documents,setDocuments]=useState()
  const [vidflag,setVidFlag]=useState(true)
  useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <>
    <Header setVidFlag={setVidFlag} setPlayed={setPlayed} lang={localStorage.getItem('lang')} toggleLang={toggleLang} items={items} />
    <AAstart  lang={localStorage.getItem('lang')}  items={items} teamStart={teamStart}/>
    <NewAbout lang={localStorage.getItem('lang')}  items={items}/>
    <OutStack lang={localStorage.getItem('lang')}  items={items}/>
    <Directions lang={localStorage.getItem('lang')}  items={items}/>
    <WhyUs lang={localStorage.getItem('lang')}  items={items}/>
    <Form lang={localStorage.getItem('lang')}  items={items}/>
    <OurTeam vidflag={vidflag} setVidFlag={setVidFlag} played={played} isTeamReady={isTeamReady} lang={localStorage.getItem('lang')} items={items}/>
    <Confa lang={localStorage.getItem('lang')}  items={items}/>
    <Vacancy lang={localStorage.getItem('lang')}  items={items}/>
    <Contacts lang={localStorage.getItem('lang')}  items={items} documents={documents}/>
    <Footer lang={localStorage.getItem('lang')}  items={items}/>
    </>
  )
}

export default Main