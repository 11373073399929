import Carret from './carret'
import React, { useEffect, useState } from 'react'
import FormVacansy from '../components/FormVacansy'
function Accordion({componentItems, titleEn, subtitleEn ,title, subtitle, inOfficeOnly, responsibility,responsibilityEn, requirements, terms,requirementsEn, termsEn, index,lang}) {
    const [isActive, setIsActive] = useState(false)
    const [isActivet, setIsActivet] = useState(false)
   
    const handelOpenTab = () => {
        
        setIsActive(prevState => !prevState)
        
        
        //let height
        // setTimeout(()=>{
        //     let actTab= document.querySelector('.vacancy__tabs__item--active')
        //     if(actTab){
        //         height = actTab.clientHeight
        //         actTab.style.height=0
        //     }
            
        
        // },0.01)
        // setTimeout(()=>{
        //     let actTab= document.querySelector('.vacancy__tabs__item--active')
        //     actTab.style.height=`${height}px`
        
        // },100)
    }

    function handleDeal(){
        setIsActivet(prevState => !prevState)
    }
    let updateData = () => {
        setIsActivet(prevState => !prevState)
    }


    return (

        <>
         <FormVacansy isActive={isActivet} updateData={updateData} lang={lang} page={''}/>
         <div className='vacancy__tabs__item'>
        <div onClick={handelOpenTab} className="vacancy__tabs__item__header">
            <div>
                <h2 style={{color: isActive ? '#B5E527' : '#16192B' }} className="vacancy__tabs__item__header--title">{title}</h2>
                <h3 className="vacancy__tabs__item__header--subtitle">{subtitle}</h3>
            </div>
            <Carret/>
        </div>
        
        
        <div style={{opacity: isActive ? '1' : '1', pointerEvents: isActive ? 'all' : 'none'}}  className={isActive ? "vacancy__tabs__item--active activet" : "vacancy__tabs__item--active"}>
            {inOfficeOnly ? <p className="in-office-only">({lang!=='ru'?'Office work only':'Работа только в офисе'})</p> : null}
            
            <h3 className="vacancy__tabs__item--active__title">{
            subtitle=='(Somaliai Mass Media and Social Networks)' ||  subtitle=='(Bangladeshi Mass Media and Social Networks)' ||  subtitle=='(Swahili Mass Media and Social Networks)'
            ?
            'Responsibilities'
            :
            lang!=='ru'?'Responsibilities':'Обязанности' 
            
            }</h3>
            
            <ul className="vacancy__tabs__item--active__text">
                {React.Children.toArray(responsibility.split('\n').map(item => (
                    <li className="vacancy__tabs__item--active__text__item">{item}</li>
                )))}

            </ul>

            <h3 className="vacancy__tabs__item--active__title">
                
            {
            subtitle=='(Somaliai Mass Media and Social Networks)' ||  subtitle=='(Bangladeshi Mass Media and Social Networks)' ||  subtitle=='(Swahili Mass Media and Social Networks)'
            ?
            'Requirements'
            :
            lang!=='ru'?'Requirements':'требования'
            
            }</h3>
            <ul className="vacancy__tabs__item--active__text">
                {React.Children.toArray(requirements.split('\n').map(item => (
                    <li className="vacancy__tabs__item--active__text__item">{item}</li>
                )))}
            </ul>

            <h3 className="vacancy__tabs__item--active__title">
                {
                subtitle=='(Somaliai Mass Media and Social Networks)' ||  subtitle=='(Bangladeshi Mass Media and Social Networks)' ||  subtitle=='(Swahili Mass Media and Social Networks)'
                ?
                'Terms'
                :
                lang!=='ru'?'Terms':'условия'
                }
                </h3>
            <ul className="vacancy__tabs__item--active__text">
            {React.Children.toArray(terms.split('\n').map(item => (
                    <li className="vacancy__tabs__item--active__text__item">{item}</li>
                )))}
                
            </ul>
            <button onClick={handleDeal}  className="want-work__button">

                {
                subtitle=='(Somaliai Mass Media and Social Networks)' ||  subtitle=='(Bangladeshi Mass Media and Social Networks)' ||  subtitle=='(Swahili Mass Media and Social Networks)'
                ?
                "i'm in!"
                :
                componentItems.button
                
                }
                </button>
            

        </div>
       
    </div>
        </>
   )
}

export default Accordion

