import './App.css';
import './styles/main.scss'
import React, { useEffect } from 'react';
import { useState } from 'react';

import Loader from './components/Loader';
import getFetch from './components/fetching/getFetch';
import { Routes, Route } from 'react-router-dom';
import Main from './components/Main';
import ItScouts from './itscout/Main';
import NotFoundPage from './components/NotFoundPage';
//import Dragndrop from './components/Dragndrop';






function App() {
    let localLang = window.navigator.language
    //let state={}
    let [stLang,setState]=useState('')
    //state.lang=stLang
    
    if(!localStorage.getItem('lang')){
        localStorage.setItem('lang', localLang.includes('ru')?'ru':'en')
    }
    
    // en-US
    

    const toggleLang =()=>{
        !localStorage.getItem('lang').includes('ru')?setState(stLang='ru'):setState(stLang='en')

        
        localStorage.removeItem('lang')
        
        
        localStorage.setItem('lang', `${stLang===`ru`? 'ru' : 'en'}`)
        document.location.reload();

        
    }
    

    const [played,setPlayed]=useState(true)
    
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const fethUrl = `https://itsports.ru/app/api/itsports?locale=${localStorage.getItem('lang').includes('ru')?'ru':'en'}&populate[header][populate][logo][fields]=url&populate[header][populate][linkimg][populate][img][fields]=url&populate[footer][populate][linkimg][populate][img][fields]=url&populate[header][fields]&populate[header][populate][link][fields]&populate[main][populate][gif][fields]=url&populate[main][populate][itsports][fields]=url&populate[about][populate][swiperAbout][populate][slide][fields]=url&populate[about][populate][card][fields]&populate[tech][populate][plan][fields]&populate[tech][populate][mark][populate][img][fields]=url&populate[destinations][populate][card][fields]&populate[whyUs][populate][slide][populate][img][fields]=url&populate[team][populate][slide][populate][teammate][fields]=url&populate[conferences][populate][slide][populate][logo][fields]=url&populate[vacancies][populate][vacancie][fields]&populate[wantToWork][populate][logo][fields]=url&populate[contacts][populate][image][fields]=url&populate[contacts][populate][contact][fields]&populate[footer][populate][logo][fields]=url&populate[footer][populate][link][fields]&populate[footer][populate][logo][fields]=url&populate[newvacancies][populate][vacancy][fields]`
    
    
    useEffect(() => {
        
        
        getFetch(fethUrl,error,setError,isLoaded,setIsLoaded,items,setItems)
        
        
    }, [])

    const [isTeamReady, setIsTeamReady]=useState(false)
    const teamStart=()=>{
        setIsTeamReady(true)
    }

    return (
        <div  className="App"> 
            {
                error
                ?
                <section className='PageMask'>
                    <h1>ITSports</h1>
                    <p>ITSports предоставляет полный пакет инновационных разработок для расширения и развития бизнес-процессов в спортивном секторе. Благодаря нам спортивные организации выходят на новый уровень.</p>
                    <a href='/'>На главную</a>
                </section>
                :
                !isLoaded ?
                    <Loader loading={true}/>
                :
                <Routes >
                    <Route  path="/" element={<Main setPlayed={setPlayed} played={played} isTeamReady={isTeamReady} toggleLang={toggleLang} items={items} teamStart={teamStart}/>} />
                    <Route  path="*" element={<NotFoundPage/>} status={404}/>
                    
                    {/* <Route  path="/itscout" element={<ItScouts setPlayed={setPlayed} toggleLang={toggleLang} items={items} lang={localStorage.getItem('lang')}/>} /> */}
                </Routes> 
            }
        </div>
    
    
        )
        
}
export default App;
