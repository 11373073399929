import React,{useEffect,useState} from 'react'
import '../styles/OurAwesomeTeam.scss'
const SlideOurTeam = ({item,img,played,slides}) => {
   //const [played,setPlayed]=useState(false)
    

   const[flag,setFlag]=useState(false)



   const [isLoading, setIsLoaded] = useState(true);
   const hideLoader = ()=>{
      setIsLoaded(false)
   }
  return (
   img===0 ? 
   <div  className='swiper-slider-team__container'>
      <video onLoadedData={hideLoader} className="team-slider__vidos "  preload='auto' autoPlay={played?true:false} muted loop playsInline>
         <source  src={"https://itsports.ru/app"+item.teammate.data[img].attributes.url} type='video/mp4'/>
      </video>
      <div className="team-slider__name "><p>{item.name}</p><p>{item.prof}</p></div>
   
      <div  className={isLoading ? "swiper-slider-team__bg" : "swiper-slider-team__bg noactive"}></div>
   </div>
   :
   <div  className='swiper-slider-team__container'>
      <img onLoad={hideLoader} className="team-slider__vidos" src={"https://itsports.ru/app"+item.teammate.data[img].attributes.url} />
      <div className="team-slider__name "><p>{item.name}</p><p>{item.prof}</p></div>
   
      <div  className={isLoading ? "swiper-slider-team__bg" : "swiper-slider-team__bg noactive"}></div>
   </div>

      

   )
}

export default SlideOurTeam