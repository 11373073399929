import React, { useState, useEffect } from 'react'
import '../styles/Header.scss'
import ScrollIntoView from 'react-scroll-into-view'
import { Link } from 'react-router-dom'
import { useRef } from 'react'

const imgs = [
   'https://itsports.ru/static/assets/img/logo1.png',
   'https://itsports.ru/static/assets/img/blackArr.png',
   'https://itsports.ru/static/assets/img/burger.png',
]

function Header({lang,toggleLang,items,setPlayed,played,setVidFlag}){
   const location = window.location.href
   const [loggo] = useState(imgs[0])
   const [arr] = useState(imgs[1])
   const [burger] = useState(imgs[2])
   let punct
   //const[lang] = useContext(langContext)
   // const LangContext = React.createContext('ru');
   //const defLang = useContext(langContext)
   //const[lang,setLang]=useState(true)
   //console.log(lang)

   //const changeLang=()=>{
      
   //   setLang(ru=>!ru)
   //}
   useEffect(()=>{
      

      punct = document.querySelectorAll('.menu-header__link')
      
      const menu = document.querySelector('.header__menu')
      let goTopBtn = document.querySelector('.back_to_top');
      let backToBlack=()=>{
         
         var scrolled = window.pageYOffset;
         var coords = document.documentElement.clientHeight;

         if (scrolled > coords) {
            goTopBtn.classList.add('back_to_top-show');
         }
         if (scrolled < coords) {
            goTopBtn.classList.remove('back_to_top-show');
            menu.classList.remove('activet')
         }

      }

      function backToTop() {
         if (window.pageYOffset > 0) {
            window.scrollBy(0, -80);
            setTimeout(backToTop, 0);
         }
      }
      window.addEventListener('scroll', backToBlack);
      goTopBtn.addEventListener('click', backToTop);





      const burgerBtn = document.querySelector('.burger')
      burgerBtn.addEventListener('click',()=>{
         burgerClick()
      })
      let burgerClick=()=>{
         let exit = document.querySelector('.burger-exit')
         exit.addEventListener('click',()=>{
            menu.classList.remove('activet')
         })
         menu.classList.add('activet')
      }


      document.querySelector('.main')&&document.querySelector('.main').addEventListener('click',()=>{menu.classList.remove('activet')})
      // setTimeout(function () {
      //    document.querySelector('.header').classList.add('loaded_hiding');
         
      //    window.setTimeout(function () {
      //       document.querySelector('.header').classList.add('loaded');
      //       document.querySelector('.header').classList.remove('loaded_hiding');
      //    }, 500);
      // },500)

    
      
   },[punct])
   let componentItems = items.data.attributes.header[0]
   





   const closeMenu = () =>{
      const menu = document.querySelector('.header__menu')
      if(menu.classList.contains('activet')){
         menu.classList.remove('activet')
      }
      //menu.classList.remove('activet')
   }

   
   return (
      <header className="header">
         {/* <div className="preloader-main"></div>  */}
         <a className="back_to_top" title="Наверх"><img src={arr} alt="arrUp"/></a>
         <div className="header__container">
            <div className="header__logo">
            <Link  to="/" >
               <img className="header__logo-img activet" src={"https://itsports.ru/app"+componentItems.logo.data[1].attributes.url} alt="headerLogo"/>
               <img className="header__logo-img" src={"https://itsports.ru/app"+componentItems.logo.data[0].attributes.url} alt="headerLogo" />

            </Link>
            </div>
            <div className="header__menu-and-lang">
               <div className='burger'>
                  
                  <div className='burger__container'>
                  <img className='burger__logos' src={burger} alt="" />
                  {/* <img className='burger__logos' src="https://itsports.ru/static/assets/img/burgerBlack.svg" alt="" /> */}
                  
                  </div> 
                  
               </div>
               <div className="header__menu menu-header">
               <p className='burger-exit'></p>
                     {componentItems.link.map(item=>(
                        <a  key={item.id} id={'mhl-'+item.id} className="menu-header__link">

                           
                              <ScrollIntoView className="menu-header__link-scroll"  selector={"#"+item.selector}>
                              {item.text}
                              </ScrollIntoView>
                           
                           
                        </a>))}
                  
                  
               

               {(!location.includes('itsports.io')&&componentItems.linkimg)&&
               <div onClick={()=>{closeMenu();setVidFlag(false)}} className='header__itscout'>
                  <div>
                     <Link  id='itscoutgo' to={componentItems.linkimg.text}>
                     <img className='header__itscout-bigimg' src={"https://itsports.ru/app"+componentItems.linkimg.img.data[0].attributes.url} alt="itscout" />
                     <img className='header__itscout-smallimg' src={"https://itsports.ru/app"+componentItems.linkimg.img.data[1].attributes.url} alt="itscout" />
                     </Link>
                     
                  </div>
               </div>
               }
               

               </div>
               <div className="header__lang">
                  <span onClick={toggleLang}>{componentItems.langbtn}</span>
                  
                  {/* <langContext.Consumer>
                     {({lang, toggleLang}) => (
                     <button
                        onClick={toggleLang}
                        >
                        Toggle Theme
                     </button>
                     )}
                  </langContext.Consumer> */}
               </div>
            </div>
            
   
         </div>
         
      </header>
      )
}


export default Header