import React, { useState } from 'react'
import '../styles/NewAbout.scss'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

// import required modules
import { Navigation,Pagination,Autoplay,EffectFade } from "swiper";



const imgs = [
   'https://itsports.ru/static/assets/img/arrowR.png',
]

function NewAbout({lang,items}){
   const slides = [
      `${lang=='ru' ?'https://itsports.ru/static/assets/slider/slider1.png' :'https://itsports.ru/static/assets/img/en1.jpg'}`,
      `${lang=='ru' ?'https://itsports.ru/static/assets/slider/slider2.png' :'https://itsports.ru/static/assets/img/en2.jpg'}`,
      `${lang=='ru' ?'https://itsports.ru/static/assets/slider/slider3.png' :'https://itsports.ru/static/assets/img/en3.jpg'}`,
      `${lang=='ru' ?'https://itsports.ru/static/assets/slider/slider4.png' :'https://itsports.ru/static/assets/img/en4.jpg'}`,
   ]

   const [arrow] = useState(imgs[0])
   let componentItems = items.data.attributes.about


   return(
      <section id='about' className="what-about-us">
            <div className="what-about-us__swiper what-about-us-swiper">
               <div className="what-about-us-swiper__discription">
                  <h2 className="what-about-us-swiper__title">{componentItems.title}</h2>
                  <div className="what-about-us-swiper__text">{componentItems.description}</div>
                  <div className="swiper-buttons">
                        
                        <div className="swiper-button-prev what-about-us-swiper"></div>
                        <div className="swiper-pagination what-about-us-swiper"></div>
                        <div className="swiper-button-next what-about-us-swiper"></div>
                        
                  </div>
                  
                  
               </div>
               <Swiper 
               effect={'fade'}
               loop={true}
               navigation={{
                  nextEl: '.swiper-button-next.what-about-us-swiper',
                  prevEl: '.swiper-button-prev.what-about-us-swiper',}
               } 
               spaceBetween={2}
               autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  
                  
                }}
               speed= {1200}
                pagination={{
                  clickable: true,
                  el:'.swiper-pagination.what-about-us-swiper',
                  renderBullet: function (index, className) {
                     let menu = ['1', '2', '3', '4']
                     return '<span class="' + className + '">' + (menu[index]) + '</span>';
                  },
                }}
               modules={[Navigation,Pagination,Autoplay,EffectFade]} 
               className="what-about-us-swiper swiper">
                  <div className="swiper-wrapper what-about-us-swiper">
                     {
                        componentItems.swiperAbout.map(item=>(
                           <SwiperSlide key={item.id} className="swiper-slide what-about-us-swiper"><img src={"https://itsports.ru/app"+item.slide.data.attributes.url}  alt=''/></SwiperSlide>
                        ))
                        
                     }
                     
                  </div>
               </Swiper>
               


               <div className="what-about-us__mob mob-what">
                  <div className="mob-what__bg">
                     <img src='https://itsports.ru/static/assets/img/vector.png' alt="" />
                  </div>
                  <div className="mob-what__box">
                        <img src='https://itsports.ru/static/assets/img/bg1.png' alt="" />
                        <div className="mob-what__info ">
                           <div className="mob-what__title white">
                           {componentItems.card[0].title}
                           </div>
                           <div className="mob-what__text white">
                           {componentItems.card[0].description}
                           </div>
                        </div>
                  </div>
                  <div className="mob-what__box green small">
                        <img src='https://itsports.ru/static/assets/img/bg2.png' alt="" />
                        <div className="mob-what__info ">
                           <div className="mob-what__title">
                           {componentItems.card[1].title}
                           </div>
                           <div className="mob-what__text">
                           {componentItems.card[1].description}
                           </div>
                        </div>
                  </div>
                  <div className="mob-what__box">
                        <img src='https://itsports.ru/static/assets/img/bg3.png' alt="" />
                        <div className="mob-what__info ">
                           <div className="mob-what__title white">
                           {componentItems.card[2].title}
                           </div>
                           <div className="mob-what__text white">
                           {componentItems.card[2].description}                           
                           </div>
                        </div>
                  </div>
                  <div className="mob-what__box green">
                        <img src='https://itsports.ru/static/assets/img/bg4.png' alt="" />
                        <div className="mob-what__info ">
                           <div className="mob-what__title ">
                           {componentItems.card[3].title}
                           </div>
                           <div className="mob-what__text ">
                           {componentItems.card[3].description}
                           </div>
                        </div>
                  </div>
                  <div className="mob-what__box">
                        <img src='https://itsports.ru/static/assets/img/bg5.png' alt="" />
                        <div className="mob-what__info ">
                           <div className="mob-what__title white">
                           {componentItems.card[4].title}
                           </div>
                           <div className="mob-what__text white">
                           {componentItems.card[4].description}
                           </div>
                        </div>
                  </div>

               </div>
            </div>
      </section>
   )

}

export default NewAbout